angular.module('fingerink')
    .service('changeUserPasswordModal', function ($uibModal) {
        this.modalInstance = null;
        this.openModal = function (id) {
            this.modalInstance = $uibModal.open({
                animation: false,
                backdrop: 'static',
                size: 'lg',
                windowClass: 'signaturemodal',
                keyboard: false,
                controller: 'changeUserPasswordModalCtrl',
                templateUrl: 'web/main/team/changeUserPasswordModal.tpl.html',
                controllerAs: 'controller',
                resolve:{
                    user : userService=>userService.getUsuarios({id:id})
                }
            });
            return this.modalInstance;
        };
    })
    .controller('changeUserPasswordModalCtrl', function mostrarCtrl($q, $scope, $uibModalInstance, userService, swalService,user) {
        var that = this;
        that.user = user.data[0];
        that.cancel = () => $uibModalInstance.close();
        that.change = () => {
            that.loading = true;
            that.user.password = md5(that.user.password);
            userService.updateUserPassword(that.user).then(r => {
                that.loading = false;
                swalService.basicSwal('Correcto!', 'Se ha cambiado la contraseña correctamente', 'success');
                $uibModalInstance.close(r.data);
            }, e => {
                that.user.password  = '';
                swalService.basicSwal('Error', 'Ha habido un problema cambiar la contraseña', 'error');
                that.loading = false;
            });
        };
    });
